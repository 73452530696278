<template>
  <svg viewBox="0 0 22 25" :width="size">
    <switch>
      <g>
        <path
          d="M20.7 3.7 17.5.5C17 0 16.1-.1 15.6.4l-9 9-2.8 2.3C2 12.8 2 14.7 2 15.4c0 .3 0 .7-.1 1.1l-.5 1.8v.1c-.1.5.1.9.4 1.3.3.2.6.4.9.4.1 0 .2 0 .4-.1l1.5-.6c.4-.1.9-.2 1.4-.2.6 0 2.5-.1 3.6-1.8l2-2.5 9.3-9.3c.4-.6.3-1.4-.2-1.9zM8.3 16.5c-.6 1-1.6 1.2-2.5 1.2-.6 0-1.2.1-1.8.3l1.5-1.5c.1 0 .3.1.4.1.3 0 .6-.1.8-.3.5-.5.5-1.2 0-1.7s-1.2-.5-1.7 0c-.3.3-.4.8-.3 1.2L3 17.5l.1-.5v-.1c.1-.5.2-.9.2-1.4 0-1 .2-2 1.1-2.6l2.6-2 3.2 3.2-1.9 2.4zm2.9-3.4L8 9.9l8.5-8.5 3.2 3.2-8.5 8.5zM21.2 22.7h-20c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h20c.5 0 .9.4.9.9s-.4.9-.9.9z"
        />
      </g>
    </switch>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      default: 18
    }
  }
};
</script>
